$desktopMin: 1020px;
$tabletMax: 1019px;
$tabletMin: 868px;
$mobileMax: 867px;

body{
  background-color: white;
}

@mixin desktop(){
  @media screen and (max-width: $desktopMin) {
    @content;
  }
}

@mixin tablet(){
  @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
    @content;
  }
}

@mixin mobile(){
  @media screen and (max-width: $tabletMin) {
    @content;
  }
}

@mixin not_desktop(){
  @media screen and (max-width: $tabletMax) {
    @content;
  }
}

@mixin not_modile(){
  @media screen and (min-width: $tabletMin) {
    @content;
  }
}

.header{
  background-color: #000000;
  height: 80px;
  width: 100%;
  .title{

    padding: 23px 0px 0px 0px;
    color: #ffffff;
    float: left;
    font-weight: bold;
    .horizontal_bar{
      position: relative;
      left: -15px;
      padding: 0px 0px 0px 0px;
      height: 5px;
      width: 5px;
      border-left: 1px solid white;
      color: #007FA3;
    }
    .title_highlight{
      color: #007FA3;
    }
  }
  .title-mobile{
    position: relative;
    left: -20px;
    padding: 15px 0px 0px 0px;
    color: #ffffff;
    float: left;
    font-weight: bold;
    line-height: 26px;
    .horizontal_bar{
      position: relative;
      top: 10px;
      left: -15px;
      padding: 0px 0px 0px 0px;
      height: 5px;
      width: 5px;
      border-left: 1px solid white;
      color: #007FA3;
    }
    .title_highlight{
      color: #007FA3;
    }
  }
  .logo{
    position: relative;
    top: 15px;
    max-height: 45px;
    float: left;
    padding-left: 10px;
    padding-right: 30px;
  }
  .logo-mobile{
    position: relative;
    top: 15px;
    left: -20px;
    height: 40px;
    float: left;
    padding-right: 30px;
  }
  .nav_group{
    padding: 20px 0px 20px 0px;
    position: absolute;
    right:50px;
    .nav_spacer{
      padding-right:20px;
    }
    .nav_link{
      color: #ffffff;
    }
  }
  .nav-ham-wrapper{
    background-color: #000000;
    padding: 10px 0px 20px 20px;
    border-radius: 4px;
    position: relative;
    z-index: 100;
    float: right;
  }
  .nav-ham-icon{
    float: right;
  }
}

.container_root{
  margin-left: inherit;
  margin-right: inherit;
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.MuiContainer-root{
  margin-left: inherit;
  margin-right: inherit;
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.nav_button{
  a:link {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  a:active{
    text-decoration: none;
  }
}

@mixin hook($color,$image){
  background-color: $color;
  @include not_modile(){
    height: 350px;
  }
  @include mobile(){
    min-height: 200px;
  }
  background-repeat: no-repeat;
  background-attachment:local;
  background-position:right;
  .welcome_hook_text{
    color: white;

    @include not_modile(){
      padding: 50px 30px 0px 0px;
      max-width: 800px;
      line-height: 28pt;
      font-size: 20pt;
    }

    @include mobile(){
      padding-top: 5px;
      padding-left: 5px;
      padding-bottom: 5px;
      font-size: 20pt;
    }
  }

  .welcome_hook_highlight{
    color: #ffffff;
    line-height:normal;
    font-weight: bold;
    font-size:32pt;
  }
}

.welcome_hook{
  @include hook(#016f90,'/images/welcome_header.png');
}

.identity_hook{
  @include hook(#007FA3,'/images/friends.png');
}

.data_hook{
  @include hook(#007FA3,'/images/work_group.png');
}

.ecommerce_hook{
  @include hook(#007FA3,'/images/work.png');
}

.api_hook{
  @include hook(#007FA3,'/images/work_group.png');
  height: 150px;
}

.welcome_column{
  padding-right: 20px;
  color: rgb(85, 102, 119);
  @include not_desktop(){
    text-align: center;
  }
}

.section{
  padding: 30px 20px 30px 20px;
  background-color: #fcfcfc
}

.section-discover{
  padding: 0px 20px 30px 20px;
  background-color: #fcfcfc
}

.section-carot{
  font-size: 16pt;
  font-weight: normal;
  position: relative;
  bottom: 5px;
  color: #333333;
}

.section-caro{
  font-size: 16pt;
  font-weight: normal;
  position: relative;
  top: 70px;
  color: #333333;
}

.section_grey{
  padding: 30px 0px 30px 0px;
  background-color: #eeeeee;
}

.section_title{
  color: #007FA3;
}

.uk_learns_image{
  width: 500px;
}

.footer{
  background-color: #000000;
  height:50px;
  position: relative;
  z-index: 999;
}

.info_card_header{
  background-color: #ffffff;
  color: #007FA3;
  .MuiCardHeader-root {
    padding-bottom: 0px;
}
  // border-top: 5px solid coral
}

.MuiCard-root:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.MuiGrid-grid-md-12 {
  padding-bottom: 10px;
}

.coming_soon{
  box-shadow: none;
  border: none;
  color: black;
  font-size: 13px;
}


.nav_button_discover{
  box-shadow: none;
  border: none;
}

.nav_button_discover:hover{
  box-shadow: none;
}

.discover_info_card{
  height: 300px;
  margin-left: 20px;
  margin-bottom: 20px;
  .discover_info_card_image{
    height: 100%;
    width: 120%;
    margin-left: -5%;
  }
}

#ErrorNoFile{
  color: #b52121;
  background-color: #f3dee1;
  width: fit-content;
  margin-left: 32%;
}

#FileUploaded{
  color: #269248;
  background-color: #e4f7e6;
  width: fit-content;
  margin-left: 32%;
}

button{
  background: orange;
  color: white;
  border: none;
  padding: 10px 20px 10px 20px;
  font-size: 14pt;
  border-radius: 4px;
  margin-bottom: 5px;
}
button:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

textarea{
  width: 66%;
  margin-left: 10%;
  height: 400px;
  padding: 10px;
  margin-top: 10px;
}
.tryitout{
  padding-top: 10px;
  margin-left: 20%;
}
.tryitout-center{
  padding-top: 10px;
  margin-left: 50%;
}

.circle {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 6px;
  border: 1px solid #212121;
  color: #212121;
  text-align: center;
  margin-right: 10px;
  font-size: 10px;
}

.question {
  border-radius: 50%;
  padding: 5px 8px 5px 8px;
  color: white;
  text-align: right;
  font-size: 10px;
  background: grey;
  margin-left: 20px;
}
.quesiton:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.code{
  padding: 5px 5px 5px 5px;
  background-color: #505759;
  color: white;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.api_col_left{
  background-color: #dddddd;
}
.api_col_center{
  background-color: #ffffff;
}
.api_col_right{
  background-color: #505759;
  color: white;
  font-family: 'Source Code Pro', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.post{
  border-radius: 5px;
  background-color: #4299e1;
  color:white;
  padding: 8px;
  width: 35px;
  font-size: 14pt;
}

.post-text{
  font-weight: bold;
  padding-left: 10px;
  font-size: 14pt;
  line-height: 24pt;
}

.put{
  border-radius: 5px;
  background-color: #ed8936;
  color:white;
  padding: 8px;
  width: 35px;
  font-size: 14pt;
}

.put-text{
  font-weight: bold;
  padding-left: 10px;
  font-size: 14pt;
  line-height: 24pt;
}

.delete{
  border-radius: 5px;
  background-color: #f56565;
  color:white;
  padding: 8px;
  width: 35px;
  font-size: 14pt;
}

.delete-text{
  font-weight: bold;
  padding-left: 10px;
  font-size: 14pt;
  line-height: 24pt;
}

.get-text{
  font-weight: bold;
  padding-left: 10px;
  font-size: 14pt;
  line-height: 24pt;
}

.get{
  border-radius: 5px;
  background-color: #48bb78;
  lineheight: 30px;
  color:white;
  padding: 8px;
  width: 35px;
  font-size: 14pt;
}

.try-it{
  position: relative;
  border-radius: 5px;
  background-color: #ed8936;
  lineheight: 30px;
  color:white;
  padding: 8px;
  width: 50px;
  font-size: 10pt;
  float:right;
  bottom: 5px ;
  text-decoration: none;
}

.try-it-caret{
  position: relative;
  top:2px;
}

.markdown-nav{
  background-color: #f0f3f6;
}

%link{
  color: #001010;
  text-decoration: none;
  padding: 10px;
}

%link-hover{
  @extend %link;
  background-color:#d0d3d6;
}

%header-font{
  font-family: 'Roboto', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.markdown-nav-wrapper{
  padding-top: 50px;
  a:link {
    @extend %link;
  }

  a:visited {
    @extend %link;
  }

  a:hover {
    @extend %link-hover;
  }

  a:active {
    @extend %link-hover;
  }
}
.markdown-nav-item{
  @extend %header-font;
  font-size: 12pt;
  //line-height: 24pt;
}
.markdown-nav-text{
  @extend %header-font;
  padding-left:10px;
  font-weight:500;
}
.markdown-nav-item-bold{
  @extend %header-font;
  font-size: 12pt;
  //line-height: 24pt;
  font-weight: bold;
}
.markdown-wrapper{
  word-wrap: break-word;
  overflow-x: scroll;
  font-size: 12pt;
  margin-left: 50px;
  blockquote{
    overflow-x: scroll;
    position: relative;
    left:-40px;
    p{
      padding:5px;
      background-color: #efefef;
    }
  }
  h1{
    @extend %header-font;
    padding-top: 20px;
    color: #007FA3;
  }
  h2{
    @extend %header-font;
    padding-top: 20px;
  }
  h3{
    @extend %header-font;
    padding-top: 20px;
  }
  h4{
    @extend %header-font;
    padding-top: 20px;
  }
  table{
    border: 1px solid #dfe2e6;
    padding: 5px;
    font-size: 10pt;
    border-spacing: 0px;
  }
  td {
    padding: 10px;
    font-size: 16px;
  }
  th{
    background: white;
    padding-bottom: 8px;
    padding-top: 5px;
  }
  tr:nth-child(even) {
    background: #ffffff
  }
  tr:nth-child(odd) {
    background: #f0f3f6
  }
  .types{
    color: rgb(66, 152, 225);
    font-size: 14px;
    padding-top: 0px;
    padding-left: 7px;
  }
  p{
    padding:0px;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  .required {
    color: rgb(225, 66, 100);
  }
  .dot_200 {
    height: 15px;
    width: 15px;
    background-color: #7ac37a;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
  .dot_400 {
    height: 15px;
    width: 15px;
    background-color: #ffc56f;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
  .dot_500 {
    height: 15px;
    width: 15px;
    background-color: #eb7f7f;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
  button{
    float: right;
    background-color: #f7f8f9;
    color: #4298e1;
    cursor: pointer;
    padding: 18px;
    width: 30;
    padding: 5px;
    margin-bottom: 20px;
    border: gray;
  }
  pre{
    overflow-x: scroll;
    padding: 5px;
    background-color: #f7f8f9;
  }
}
.markdown-hidden{
  display: none;
  visibility: hidden;
}
.markdown-spacer{
  background-color: white;
}
.markdown-content-wrapper{
  position: relative;
  top: -35px;
}
code{
  font-size: 12px;
}
.caret{
  position: relative;
  font-size: 10pt;
  color: #333344;
  padding-left: 10px;
  bottom:25px;
  right:10px;
  font-weight: 600;
  float:right;
}
.hidden {
  display: none;
}
.visible {
  display: block;
}

#overview_subheading {
  // position: absolute;
  color: #655f5fa6;
}

.customer_nav_wrapper {
  background-color: #003057;
  padding-top: 10px;
  padding-bottom: 5px;
}

#customer_nav{
  list-style-type: none;
  font-size: 18px;
  margin:0px;
  padding:0px;
  display: inline;
  position: sticky;
}

.li-customer{
  @include mobile(){
    padding: 0.3rem 0rem;;
  }
  @include not_modile(){
    padding: 1rem 0rem;

  }
  a{
    display: block;
    padding-right: 32px;
    text-decoration: none;
    color: white;
  }
  a:hover{
    color: #b6d1ec;
  }
}

.home_page_subheader{
  font-size: 12px;
}

.home_page_text{
  color: rgb(85, 102, 119)
}

/* Style The Dropdown Button */
.dropbtn {
  color: white;
  border: none;
  cursor: pointer;
  list-style-type: none;
  font-size: 20px;
  margin:0px;
  padding:0px;
  display: inline;
  padding-bottom: 50px;
  padding-right:'20px'
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: -block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropbtn:hover .dropdown-content {
  display: inline-block;
  margin-top: 2%;
  margin-left: -13%;
}

.codehilite .hll { background-color: #ffffcc }
.codehilite  { background: #f8f8f8; }
.codehilite .c { color: #408080; font-style: italic } /* Comment */
.codehilite .err { border: 1px solid #FF0000 } /* Error */
.codehilite .k { color: #008000; font-weight: bold } /* Keyword */
.codehilite .o { color: #666666 } /* Operator */
.codehilite .ch { color: #408080; font-style: italic } /* Comment.Hashbang */
.codehilite .cm { color: #408080; font-style: italic } /* Comment.Multiline */
.codehilite .cp { color: #BC7A00 } /* Comment.Preproc */
.codehilite .cpf { color: #408080; font-style: italic } /* Comment.PreprocFile */
.codehilite .c1 { color: #408080; font-style: italic } /* Comment.Single */
.codehilite .cs { color: #408080; font-style: italic } /* Comment.Special */
.codehilite .gd { color: #A00000 } /* Generic.Deleted */
.codehilite .ge { font-style: italic } /* Generic.Emph */
.codehilite .gr { color: #FF0000 } /* Generic.Error */
.codehilite .gh { color: #000080; font-weight: bold } /* Generic.Heading */
.codehilite .gi { color: #00A000 } /* Generic.Inserted */
.codehilite .go { color: #888888 } /* Generic.Output */
.codehilite .gp { color: #000080; font-weight: bold } /* Generic.Prompt */
.codehilite .gs { font-weight: bold } /* Generic.Strong */
.codehilite .gu { color: #800080; font-weight: bold } /* Generic.Subheading */
.codehilite .gt { color: #0044DD } /* Generic.Traceback */
.codehilite .kc { color: #008000; font-weight: bold } /* Keyword.Constant */
.codehilite .kd { color: #008000; font-weight: bold } /* Keyword.Declaration */
.codehilite .kn { color: #008000; font-weight: bold } /* Keyword.Namespace */
.codehilite .kp { color: #008000 } /* Keyword.Pseudo */
.codehilite .kr { color: #008000; font-weight: bold } /* Keyword.Reserved */
.codehilite .kt { color: #B00040 } /* Keyword.Type */
.codehilite .m { color: #666666 } /* Literal.Number */
.codehilite .s { color: #BA2121 } /* Literal.String */
.codehilite .na { color: #7D9029 } /* Name.Attribute */
.codehilite .nb { color: #008000 } /* Name.Builtin */
.codehilite .nc { color: #0000FF; font-weight: bold } /* Name.Class */
.codehilite .no { color: #880000 } /* Name.Constant */
.codehilite .nd { color: #AA22FF } /* Name.Decorator */
.codehilite .ni { color: #999999; font-weight: bold } /* Name.Entity */
.codehilite .ne { color: #D2413A; font-weight: bold } /* Name.Exception */
.codehilite .nf { color: #0000FF } /* Name.Function */
.codehilite .nl { color: #A0A000 } /* Name.Label */
.codehilite .nn { color: #0000FF; font-weight: bold } /* Name.Namespace */
.codehilite .nt { color: #008000; font-weight: bold } /* Name.Tag */
.codehilite .nv { color: #19177C } /* Name.Variable */
.codehilite .ow { color: #AA22FF; font-weight: bold } /* Operator.Word */
.codehilite .w { color: #bbbbbb } /* Text.Whitespace */
.codehilite .mb { color: #666666 } /* Literal.Number.Bin */
.codehilite .mf { color: #666666 } /* Literal.Number.Float */
.codehilite .mh { color: #666666 } /* Literal.Number.Hex */
.codehilite .mi { color: #666666 } /* Literal.Number.Integer */
.codehilite .mo { color: #666666 } /* Literal.Number.Oct */
.codehilite .sa { color: #BA2121 } /* Literal.String.Affix */
.codehilite .sb { color: #BA2121 } /* Literal.String.Backtick */
.codehilite .sc { color: #BA2121 } /* Literal.String.Char */
.codehilite .dl { color: #BA2121 } /* Literal.String.Delimiter */
.codehilite .sd { color: #BA2121; font-style: italic } /* Literal.String.Doc */
.codehilite .s2 { color: #BA2121 } /* Literal.String.Double */
.codehilite .se { color: #BB6622; font-weight: bold } /* Literal.String.Escape */
.codehilite .sh { color: #BA2121 } /* Literal.String.Heredoc */
.codehilite .si { color: #BB6688; font-weight: bold } /* Literal.String.Interpol */
.codehilite .sx { color: #008000 } /* Literal.String.Other */
.codehilite .sr { color: #BB6688 } /* Literal.String.Regex */
.codehilite .s1 { color: #BA2121 } /* Literal.String.Single */
.codehilite .ss { color: #19177C } /* Literal.String.Symbol */
.codehilite .bp { color: #008000 } /* Name.Builtin.Pseudo */
.codehilite .fm { color: #0000FF } /* Name.Function.Magic */
.codehilite .vc { color: #19177C } /* Name.Variable.Class */
.codehilite .vg { color: #19177C } /* Name.Variable.Global */
.codehilite .vi { color: #19177C } /* Name.Variable.Instance */
.codehilite .vm { color: #19177C } /* Name.Variable.Magic */
.codehilite .il { color: #666666 } /* Literal.Number.Integer.Long */

$sidebar-bg-color: #f0f3f6;
$sidebar-color: #001010;
$highlight-color: #586666;
$submenu-bg-color: #f0f3f6;
$submenu-bg-color-collapsed: #f0f3f6;
@import '~react-pro-sidebar/dist/scss/styles.scss';

.image_data{
  width: 600px;
  border-radius: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

.loading_spin_icon{
  -webkit-animation:spin 1.5s linear infinite;
  -moz-animation:spin 1.5s linear infinite;
  animation:spin 1.5s linear infinite;
}

.timeline_layout{
  th, td{
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #ddd;
  }
}
